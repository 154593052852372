<template>
  <div class="row">
    <div class="col-md-8">
      <!-- 검색 인풋 -->
      <div>
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          v-model="search"
        />
      </div>
      <div v-if="searchDatalist.length > 0">
        <!-- List of remaining news -->
        <div class="list-group mt-4">
          <div
            v-for="(news, index) in searchDatalist"
            :key="index"
            class=" list-group-item list-group-item-action border-0 news-item d-flex align-items-center"
            @click="navigateToDetail(news.id, news.category)"
          >
            <img
              :src="news.imageURL"
              class="me-4 news-item-image"
              alt="News Image"
            />
            <div class="mouseon">
              <h5 class="mb-1 ">{{ news.title }}</h5>
              <p>{{ news.comment }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <p class="text-muted">기사가 없거나 기사를 불러오는데 실패 했습니다.</p>
      </div>
    </div>

    <div class="col-md-4 pl-md-4">
      <div class="sidebar-box mb-3 border border-black margin-design">
        <h5 class="sidebar-title">여기에 광고 넣어야함</h5>
        <p class="sidebar-content">광고</p>
      </div>
      <div class="d-flex flex-column">
        <div class="sidebar-box mb-3 margin-design" style="width: auto">
          <div
            v-for="(news, index) in all_lastestnewsData.slice(0, 7)"
            :key="index"
            class="border-bottom border-black mouseon mb-2"
          >
            <p
              @click="navigateToDetail(news.id, news.category)"
              class="p fw-bold"
            >
              {{ news.title }}
            </p>
            <p></p>
            <p
              @click="navigateToDetail(news.id, news.category)"
              class="p fw-light"
            >
              Read More
              <span> >> </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateViews } from "@/fetchData";


export default {
  props: {
    all_topnewsData: {
      type: Array,
      required: true,
    },
    all_lastestnewsData:{
      type: Array,
      required: true,
    },
    
    categoryName: String,
  },
  data() {
    return {
      searchDatalist: [], //검색한 데이터를 담을 배열
      search : "", //검색어
    };
  },
  watch: {
    all_topnewsData: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.loadData();
      },
      immediate: true,
    },
  },
  methods: {
    loadData() {
      this.searchDatalist = [];
      this.all_topnewsData.forEach((news) => {
        if (
          news.title.toLowerCase().includes(this.search.toLowerCase()) ||
          news.comment.toLowerCase().includes(this.search.toLowerCase())
        ) {
          this.searchDatalist.push(news);
        }
      });
    },
    async navigateToDetail(itemId, categoryName) {
      const routeData = {
        name: "ItemDetail",
        params: { categoryName: categoryName, id: itemId },
      };

      // Update views count for the item
      try {
        await updateViews(categoryName, itemId);
        console.log("Views updated for item:", itemId);
      } catch (error) {
        console.error("Error updating views:", error);
      }

      // Get the current base URL
      const baseUrl = window.location.origin;

      // Create the URL for the route
      const url = this.$router.resolve(routeData).href;

      // Open the URL in a new tab
      window.open(baseUrl + url, "_blank");
    },
  },
};
</script>

<style scoped>
.mouseon h2:hover,
.mouseon p:hover,
.mouseon h5:hover {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}</style>
