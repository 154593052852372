<template>
  <div>
    

    <div class="row g-0">
      <div class="col-md-9 pe-5">
        <!-- 메인 뉴스 출력 디자인 -->
        <div
          class="mb-3"
          style="width: 100%"
          v-if="all_lastestnewsData && all_lastestnewsData.length"
        >
          <div class="row g-0">
            <div class="col-md-6 mb-3">
              <img
                :src="all_lastestnewsData[0].imageURL"
                class="img-fluid imageon rounded-start news-main-image mouseon"
                alt="Main News Image"
                style="width: 100%; height: 300px; object-fit: cover"
                @click="
                  navigateToDetail(
                    all_lastestnewsData[0].id,
                    all_lastestnewsData[0].category
                  )
                "
              />
            </div>
            <div class="col-md-6">
              <div class="card-body mouseon ms-3">
                <h5
                  @click="
                    navigateToDetail(
                      all_lastestnewsData[0].id,
                      all_lastestnewsData[0].category
                    )
                  "
                  class="card-title mb-1 fw-bold fs-3"
                >
                  {{ all_lastestnewsData[0].title }}
                </h5>
                <p
                  @click="
                    navigateToDetail(
                      all_lastestnewsData[0].id,
                      all_lastestnewsData[0].category
                    )
                  "
                  class="card-text fw-lighter"
                >
                  {{ all_lastestnewsData[0].comment }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 최신 뉴스 출력 디자인 카드 형식으로 3개만 -->
        <div
          class="row mb-5 row-cols-1 row-cols-md-3 g-4"
          v-if="all_lastestnewsData && all_lastestnewsData.length > 1"
        >
          <div
            class="col pe-3 ps-3"
            v-for="(news, index) in all_lastestnewsData.slice(1, 4)"
            :key="index"
            :class="{ 'fade-in': showSections[index] }"
          >
            <div class="card" style="width: 100%">
              <img
                :src="news.imageURL"
                class="card-img-top imageon news-image mouseon"
                alt="News Image"
                style="height: 150px; object-fit: cover"
                @click="navigateToDetail(news.id, news.category)"
              />
              <div class="card-body mouseon margin-design">
                <h5
                  @click="navigateToDetail(news.id, news.category)"
                  class="card-title fw-bold"
                >
                  {{ news.title }}
                </h5>
                <p
                  @click="navigateToDetail(news.id, news.category)"
                  class="card-text fw-lighter"
                >
                  {{ news.comment }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- 여기는 나머지 기사를 출력 하는 디자인 여기 디자인은 건들지 않기 -->
        <div style="max-width: 100%">
          <div
            class="row g-0 pb-3 mb-5 border-bottom border-black"
            v-for="(newslist, i) in all_lastestnewsData.slice(4, 6)"
            :key="i"
          >
            <div
              class="col-md-4"
              style="width: 300px; height: 150px; overflow: hidden"
            >
              <img
                :src="newslist.imageURL"
                class="img-fluid imageon rounded-start mouseon"
                style="width: 100%; height: 100%; object-fit: cover"
                @click="navigateToDetail(newslist.id, newslist.category)"
              />
            </div>

            <div class="col-md-8">
              <div class="card-body mouseon">
                <h5
                  @click="navigateToDetail(newslist.id, newslist.category)"
                  class="card-title mb-2 fw-bold"
                >
                  {{ newslist.title }}
                </h5>
                <p class="ps-2 fw-lighter newscomment">
                  {{ newslist.comment }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 여기는 사이드바 -->
      <div class="side col-md-3 pl-md-4 bg-white">
        <div class="sidebar-box mb-3 border border-black margin-design">
          <h5 class="sidebar-title">여기에 광고 넣어야함</h5>
          <p class="sidebar-content">광고</p>
        </div>
        <div class="d-flex flex-column">
          <div class="sidebar-box mb-3 margin-design" style="width: auto">
            <div
              v-for="(news, index) in all_lastestnewsData.slice(6, 14)"
              :key="index"
              class="border-bottom border-black mouseon mb-2"
            >
              <p
                @click="navigateToDetail(news.id, news.category)"
                class="p fw-bold"
              >
                {{ news.title }}
              </p>
              <p
                @click="navigateToDetail(news.id, news.category)"
                class="p fw-lighter"
              >
                {{ news.comment.slice(0, 200) }}...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  {{ console.log(ScrollValue) }}
</template>

<script>
export default {
  props: {
    category: {
      type: Array,
      required: true,
    },
    dbData: {
      type: Object,
      required: true,
    },

    all_lastestnewsData: {
      type: Array,
      required: true,
    },
    all_topnewsData: {
      type: Array,
      required: true,
    },
    ScrollValue: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentCategory: "",
      categoryindex: 0,
      latestNews: [],
      comment: "",
      showSections: [false, false, false], // 각 영역의 보이는 상태
    };
  },
  created() {
    this.currentCategory = this.category[this.categoryindex];
  },
  methods: {
    handleScroll() {
      const scrollValue = window.scrollY + window.innerHeight; // 현재 스크롤 위치
      const sections = document.querySelectorAll(".col"); // 각 영역 요소

      sections.forEach((section, index) => {
        // 각 영역의 상대적 위치
        const sectionPosition = section.offsetTop + section.offsetHeight / 2;

        if (scrollValue > sectionPosition) {
          this.showSections[index] = true; // 직접 상태 변경
        }
      });
    },

    navigateToDetail(itemId, category) {
      console.log(itemId, category);
      const routeData = {
        name: "ItemDetail",
        params: { categoryName: category, id: itemId },
      };

      // Get the current base URL
      const baseUrl = window.location.origin;

      // Create the URL for the route
      const url = this.$router.resolve(routeData).href;

      // Open the URL in a new tab
      window.open(baseUrl + url, "_blank");
    },
    changecomment(news) {
      return news.description.slice(0, 100);
    },
    handleSidebarPosition() {
      const sidebar = document.querySelector(".side");
      const topPosition = sidebar.offsetTop; // 사이드바의 초기 위치

      if (window.scrollY >= topPosition) {
        console.log("감지");
        sidebar.style.position = "sticky";
        sidebar.style.top = "0"
      } else {
        sidebar.style.position = "static";
      }
    },
  },
  computed: {
    search() {
      return this.$store.state.search;
    },
  },
  mounted() {
    this.latestNews = this.all_lastestnewsData.slice(6, 14);
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll(); // 초기 상태 설정
    window.addEventListener("scroll", this.handleSidebarPosition);
    
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.handleSidebarPosition);
  },
};
</script>

<style scoped>
.card-body {
  margin-left: 15px;
  padding: 15px 5px;
}
.list-group-item {
  border: none;
}
.mouseon p:hover,
.mouseon small:hover,
.mouseon h3:hover,
.mouseon h5:hover .mouseon img:hover {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
.imageon:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 0.5s;
}

.shake:hover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 1300px) {
  .card-body {
    text-align: start; /* text-start로 변경 */
    font-size: 0.8em; /* 폰트 크기 조절 */
  }
  .card-title {
    font-size: 1.2em; /* 제목 폰트 크기 조절 */
  }
  .row.g-0.mb-5.border-bottom.border-black {
    flex-direction: row; /* 요소를 수평으로 배치 */
  }
  .col-md-4 {
    width: 50% !important; /* 이미지와 텍스트의 비율 조절 */
    height: 150px !important;
  }
  .col-md-8 {
    width: 50%;
  }
  .mb-5 {
    margin-bottom: 10px;
  }
  .margin-design {
    margin-left: 0;
  }
  .pe-5 {
    padding-right: 0px !important;
  }
  .pb-5 {
    padding-bottom: 5rem !important;
  }
  .mb-5 {
    margin-bottom: 0px !important;
  }

  .newscomment {
    display: none;
  }
  .border-bottom {
    border-bottom: none !important;
  }
  .fade-in {
    opacity: 0;
    transition: opacity 0.5s;
  }

  .fade-in.fade-in {
    opacity: 1;
  }
}
@media screen and (max-width: 760px) {
  .card-img-top {
    height: 360px !important;
  }
  .card-body{
    margin-left: 0px;
  }
  .ms-3{
    margin-left: 0px !important;
  }
}
</style>
