import { createApp } from 'vue';
import { createStore } from 'vuex';
import 'bootstrap/dist/css/bootstrap.css';
import BootstrapIcons from 'bootstrap-icons-vue';
import router from './router';
import App from './App.vue';
import stateManage from './stateManage'; // stateManage.js 파일 경로를 확인하세요.

// Vuex Store 생성
const store = createStore(stateManage);

const app = createApp(App);

  


app.use(BootstrapIcons);
app.use(router);
app.use(store); // 애플리케이션에 스토어 사용 선언

app.mount('#app');
