<template>
  <div class="header">
    <!-- 로고 중앙 정렬 -->
    <router-link :to="MenuLogo[0].url" class="desktop-logo">
      <div class="logo-icon-container">
        <img
          src="../코리아인사이더.png"
          class="border-0 rounded img-style"
          alt="로고 이미지"
        />
      </div>
    </router-link>
    <!-- 아이콘 오른쪽에 위치 -->
    <div class="btn mobile" @click="jumpToseach(), (isNavOpen = !isNavOpen)">
      <i class="fas fa-search"></i>
    </div>
  </div>

  <div class="navbar">
    <ul class="navbar-nav">
      <li
        v-for="(item, i) in MenuItems"
        :key="i"
        class="nav-item mouseon"
        :class="{ 'first-item': i === 0, clickedItem: i === clickedItem }"
      >
        <router-link
          class="nav-link space"
          :to="item.url"
          @click="setActiveCategory(item.label), toggleNav(i)"
        >
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { fetchData } from "@/fetchData";

export default {
  data() {
    return {
      MenuItems: [
        { label: "Home", url: "/" },
        { label: "Hot Issue", url: "/category/hotissue" },
        { label: "Society", url: "/category/society" },
        { label: "Economy", url: "/category/economy" },
        { label: "Science & Tech", url: "/category/science" },
        { label: "Politics", url: "/category/politics" },
        { label: "International", url: "/category/international" },
        { label: "North Korea", url: "/category/northkorea" },
      ],
      MenuLogo: [{ label: "../Logo.png", url: "/" }],
      activeCategory: "hotissue",
      dbData: {},
      all_category: [
        "economy",
        "hotissue",
        "international",
        "northkorea",
        "politics",
        "science",
        "society",
      ],
      search: "",
      isNavOpen: false,
      isDesktop: window.innerWidth >= 1300,
      clickedItem: null,
      icon: "fas fa-bars fa-2x",
      currentDateTime: this.formatDate(new Date()),
    };
  },
  props: {
    ScrollValue: Number,
  },
  async created() {
    this.fetchData();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.isDesktop = window.innerWidth >= 1300;
    });
    this.interval = setInterval(() => {
      this.currentDateTime = this.formatDate(new Date());
    }, 1000);
  },

  beforeUnmount() {
    window.removeEventListener("resize");
    clearInterval(this.interval); // 컴포넌트 파괴 시 인터벌 제거
  },
  computed: {
    collapseTarget() {
      return `navbarNavAltMarkup-${this._uid}`;
    },
    collapseClass() {
      return `collapse navbar-collapse`;
    },
  },

  methods: {
    toggleNav(i) {
      if (this.clickedItem === i) {
        this.clickedItem = null;
      } else {
        this.clickedItem = i;
      }
      this.isNavOpen = !this.isNavOpen;
    },
    formatDate(date) {
      const days = ["일", "월", "화", "수", "목", "금", "토"];
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hour = String(date.getHours()).padStart(2, "0");
      const minute = String(date.getMinutes()).padStart(2, "0");
      const dayOfWeek = days[date.getDay()];

      return `${year}-${month}-${day}(${dayOfWeek}요일) ${hour}:${minute}`;
    },

    setActiveCategory(category) {
      this.activeCategory = category;
      console.log(`Active category is now ${category}`);
    },

    jumpToseach() {
      this.$router.push({ name: "Search" });
      this.icon = "fas fa-bars fa-2x";
    },
    async fetchData() {
      try {
        const categories = [
          "economy",
          "hotissue",
          "international",
          "northkorea",
          "politics",
          "Science & Tech",
          "society",
        ];

        const dbData = {};

        for (const category of categories) {
          // fetchData 함수를 사용하여 Firestore 쿼리를 수행합니다.
          const items = await fetchData(category);
          dbData[category] = items;
        }

        this.dbData = dbData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    updateSearch() {
      this.$store.commit("updateSearch", this.search);
    },
  },
  watch: {
    isNavOpen(newValue, oldValue) {
      if (!newValue && !this.isDesktop) {
        this.$nextTick(() => {
          this.$refs.navbarCollapse.classList.add("slide-up");
        });
      } else {
        this.$refs.navbarCollapse.classList.remove("slide-up");
      }
    },
  },
};
</script>

<style scoped>

.logo-icon-container {
  display: flex;
  align-items: center;
}

.logo-icon-container .img-style {
  max-width: 100%;
  height: auto;
}
.mouseon {
  transition: all 0.5s ease;
  text-align: center; /* 수정 */
}


.mouseon:hover {
  background-color: black;
  color: white;
}
.clickedItem {
  background-color: black;
  color: white;
}
.btn:hover {
  background-color: black;
  color: white;
}

.img-style {
  width: 300px;
  height: 110px;
}
.fa-search.mobile {
  display: none;
}

.nav-item {
  margin-right: 0; /* 수정 */
  /* padding-left: 20px;
  padding-right: 20px; */
  flex-grow: 1;
}

.navbar {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 1rem 0px;
}

.navbar-toggler {
  display: none;
}
.mobile {
  color: black;
  font-size: 1.7rem;
  font-weight: bold;
  margin-left: auto; /* 검색 아이콘을 오른쪽으로 이동 */
}

.navbar-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-between; /* 수정 */
}

.nav-link {
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
}

.ms-6 {
  display: block;
  margin-left: 6rem;
}
.header {
  position: relative; /* 또는 absolute, fixed 등 */
  z-index: 1000; /* 다른 요소보다 높은 값을 설정 */
  display: flex;
  justify-content: space-between; /* 아이템들을 좌우 양쪽으로 분배합니다. */
  align-items: center; /* 수직 중앙 정렬 */
}
.desktop-logo {
  display: flex;
  justify-content: center; /* 로고 이미지를 수평 중앙 정렬 */
  flex-grow: 1; /* 로고가 header 내부에서 가능한 많은 공간을 차지하도록 합니다. */
}
@media (max-width: 767px) {
  .navbar {
    padding: 0.5rem 0;
  }

  .img-style {
    width: 150px;
    height: 55px;
  }

  .nav-item {
    font-size: 0.8rem;
    display: inline-block;
    white-space: nowrap;
    margin: 0 10px;
  }
  .nav-link {
    font-size: 0.8rem;
  }

  .fa-search.desktop {
    display: none;
  }

  .fa-search.mobile {
    display: block;
    font-size: 1.2rem;
  }
  .navbar-nav::-webkit-scrollbar {
    display: none; /* 스크롤바 숨김 */
  }
  .navbar-nav {
    white-space: nowrap;
    overflow-x: auto; /* 가로 스크롤 활성화 */
    -webkit-overflow-scrolling: touch; /* 모바일에서 부드러운 스크롤링 지원 */
    scrollbar-width: none; /* Firefox 용 */
    -ms-overflow-style: none; /* IE 및 Edge 용 */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar {
    padding: 0.7rem 0;
  }

  .img-style {
    width: 200px;
    height: 73px;
  }
  .nav-link {
    font-size: 0.75rem;
  }

  .nav-item {
    font-size: 0.9rem;
  }

  .fa-search.desktop {
    display: none;
  }

  .fa-search.mobile {
    display: block;
    font-size: 1.3rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .navbar {
    padding: 0.8rem 0;
  }

  .img-style {
    width: 250px;
    height: 92px;
  }
  .nav-link {
    font-size: 0.9rem;
  }

  .nav-item {
    font-size: 1rem;
  }

  .fa-search.desktop {
    display: none;
  }

  .fa-search.mobile {
    display: block;
    font-size: 1.3rem;
  }
}
</style>
