<template>
  <div v-if="edit">

    <form @submit.prevent="submitForm" class="my-4">
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="title" class="form-label">Title</label>
          <input
            v-model="title"
            type="text"
            id="title"
            class="form-control"
            placeholder="제목"
            required
          />
        </div>
        <div class="col-md-4 mb-3">
          <label for="author" class="form-label">Author</label>
          <input
            v-model="author"
            type="text"
            id="author"
            class="form-control"
            placeholder="기자명"
            required
          />
        </div>
        <div class="col-md-4 mb-3">
          <label for="category" class="form-label">Category</label>
          <select v-model="category" id="category" class="form-select" required>
            <option disabled value="">카테고리를 선택해주세요</option>
            <option v-for="option in categories" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label for="content" class="form-label">Content</label>
        <QuillEditor
          ref="myQuillEditor"
          @blur="onEditorBlur"
          @focus="onEditorFocus"
          @ready="onEditorReady"
          @text-change="onEditorChange"
          class="form-control custom-editor"
          style="height: 800px"
          :toolbar="toolbar"
          required
        />
      </div>

      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
      >
        미리보기
      </button>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">미리보기</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="description" v-html="description"></div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-3">
        <label for="comment" class="form-label"
          >Comment {{ comment.length }}</label
        >
        <input
          v-model="comment"
          type="text"
          @input="checkLength()"
          id="comment"
          class="form-control"
          placeholder="간단한 설명"
          required
        />
      </div>

      <div class="mb-3">
        <label for="createdDate" class="form-label">Created Date</label>
        <input
          v-model="createdDate"
          type="date"
          id="createdDate"
          class="form-control"
          required
        />
      </div>
      
      <div class="mb-3">
        <label for="createdTime" class="form-label">Created Time</label>
        <input
          v-model="createdTime"
          type="time"
          id="createdTime"
          class="form-control"
          required
        />
      </div>
      <button type="submit" class="btn btn-primary">글 발행</button>
    </form>
    <input type="file" ref="fileInput" />
  </div>

  <div v-else>
    <h1>비밀번호를 입력해주세요</h1>
    <p class="text-danger" v-if="password_wrong">비밀번호가 틀렸습니다</p>
    <input v-model="input_password" type="password" />
    <button @click="checkpassword(input_password)">확인</button>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { publishArticle } from "@/fetchData";
import Quill from "quill";
import { SHA256 } from "crypto-js";

let SizeStyle = Quill.import("attributors/style/size");
SizeStyle.whitelist = [
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "30px",
  "32px",
  "36px",
  "48px",
  "60px",
  "72px",
  "96px",
];
Quill.register(SizeStyle, true);

export default {
  components: {
    QuillEditor,
  },
  props: {
    all_lastestnewsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      classObject: {
        "text-danger": false,
      },
      title: "",
      author: "",
      description: "",
      category: "",
      createdDate: "",
      createdTime: "",
      comment: "",
      categories: [
        "economy",
        "hotissue",
        "international",
        "northkorea",
        "politics",
        "science",
        "society",
      ],
      edit: false,
      selectedIndex: -1,
      password_wrong: false,
      password: SHA256(process.env.VUE_APP_PASSWORD).toString(),
      input_password: "",
      count: 0,
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ background: [] }, { color: [] }],
        [{ font: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [
          {
            size: [
              "10px",
              "12px",
              "14px",
              "16px",
              "18px",
              "20px",
              "24px",
              "30px",
              "32px",
              "36px",
              "48px",
              "60px",
              "72px",
              "96px",
            ],
          },
        ],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["clean"],
        ["link", "image", "video"],
      ],
    };
  },

  methods: {
    SaveChange(){
      this.title = this.all_lastestnewsData[this.selectedIndex].title;
      this.author = this.all_lastestnewsData[this.selectedIndex].author;
      this.$refs.myQuillEditor.setHTML(this.all_lastestnewsData[this.selectedIndex].description);
      this.category = this.all_lastestnewsData[this.selectedIndex].category;
      this.comment = this.all_lastestnewsData[this.selectedIndex].comment;
      this.createdDate = this.all_lastestnewsData[this.selectedIndex].createdDate;
      this.createdTime = this.all_lastestnewsData[this.selectedIndex].createdTime;
      this.selectedIndex = -1;
    },
    async submitForm() {
      const article = {
        title: this.title,
        author: this.author,
        description: this.description,
        category: this.category,
        comment: this.comment,
        createdDate: this.formatDateTime(this.createdDate, this.createdTime),
      };

      try {
        const imageFile = this.$refs.fileInput.files[0]; // Get the selected image file
        const docId = await publishArticle(article, imageFile); // Pass the image file to publishArticle
        alert(`글이 발행되었습니다. ID: ${docId}`);
        this.$router.push({ name: "Home" });
      } catch (error) {
        console.error("Error publishing article:", error);
      }
    },
    checkNews(index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = -1; // 이미 선택된 아이템을 다시 클릭하면 선택 해제
      } else {
        this.selectedIndex = index; // 클릭된 인덱스 저장
      }
    },
    checkpassword(inputUser) {
      if (SHA256(inputUser).toString() == this.password) {
        this.password_wrong = false;
        this.edit = true;
      } else {
        this.password_wrong = true;
        this.edit = false;
        this.count += 1;
        if (this.count >= 5) {
          alert("비밀번호를 5번 이상 틀렸습니다. 잠시후에 다시시도 해주세요");
          this.count = 0;
          this.$router.push({ name: "Home" });
        }
      }
    },
    // 문자열이 100자 이상일때 경고창 띄우기
    checkLength() {
      if (this.comment.length > 100) {
        alert("100자 이하로 입력해주세요");
        // 100자 밑으로 문자열 설정하기
        const wronglength = this.comment.length - 100
        this.comment = this.comment.substring(0,this.comment.length = wronglength);
      }
    },

    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    formatDateTime(date, time) {
      const formattedDate = new Date(date).toISOString().split("T")[0];
      const formattedTime = time
        .split(":")
        .map((part) => part.padStart(2, "0"))
        .join(":");
      return `${formattedDate} ${formattedTime}`;
    },
    onEditorChange() {
      if (this.$refs.myQuillEditor) {
        this.description = this.$refs.myQuillEditor.getHTML();
        console.log("Updated description:", this.$refs.myQuillEditor.getHTML());
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.quill) {
        this.$refs.myQuillEditor.quill.on("text-change", () => {
          let range = this.$refs.myQuillEditor.quill.getSelection();
          if (range) {
            let format = this.$refs.myQuillEditor.quill.getFormat(range);
            let sizeDropdown = document.querySelector(
              ".ql-size .ql-picker-label"
            );
            if (sizeDropdown) {
              sizeDropdown.textContent = format.size || "";
            }
          }
        });
      }
    });
  },
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.form-label {
  font-size: 1rem;
  font-weight: bold;
}

.form-control {
  font-size: 1rem;
}

.ql-editor {
  min-height: 18px;
}

.btn-primary {
  font-size: 1rem;
  font-weight: bold;
}
.description::v-deep img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
