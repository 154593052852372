<!-- app.vue -->
<template>
  <div class="custom-container">
    <Header :ScrollValue="ScrollValue"></Header>
    <div class="fade" :class="{ 'fade-in': fadeIn }">
      <MainBanner :ScrollValue="ScrollValue"></MainBanner>
    </div>
  </div>
</template>

<script>
import Header from "./components/HeaderBanner.vue";
import MainBanner from "./components/MainBanner.vue";
export default {
  name: "App",
  components: {
    Header,
    MainBanner,
  },
  data() {
    return {
      touchStartY: 0,
      ScrollValue: 0,
      refreshing: false,
      fadeIn: false,
    };
  },
  methods: {
    handleTouchStart(event) {
      if (window.scrollY === 0) {
        this.touchStartY = event.touches[0].clientY;
      }
    },
    handleTouchMove(event) {
      const touchY = event.touches[0].clientY;
      if (window.scrollY === 0 && touchY > this.touchStartY + 100) {
        // 100px 이상 아래로 드래그
        this.refreshing = true;
      }
    },
    handleTouchEnd() {
      if (this.refreshing) {
        location.reload(); // 페이지 새로고침
      }
      this.refreshing = false;
      this.touchStartY = 0;
    },
    handleScroll() {
      this.ScrollValue = window.scrollY;
    },
  },
  mounted() {
    document.addEventListener("touchstart", this.handleTouchStart);
    document.addEventListener("touchmove", this.handleTouchMove);
    document.addEventListener("touchend", this.handleTouchEnd);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    document.removeEventListener("touchstart", this.handleTouchStart);
    document.removeEventListener("touchmove", this.handleTouchMove);
    document.removeEventListener("touchend", this.handleTouchEnd);
    window.removeEventListener("scroll", this.handleScroll);
  },

  watch: {
    $route(to, from) {
      // 라우트가 변경될 때 fadeIn을 false로 설정
      this.fadeIn = true;
      this.$nextTick(() => {
        // Vue의 DOM 업데이트 사이클 후 fadeIn을 true로 설정
        this.fadeIn = true;
      });
    },
  },
};
</script>

<style>

.custom-container {
  margin: 0 70px;
  padding: 0 12px;
  overflow-x: hidden;
}
.fade {
  transition: opacity 0.5s;
}

.fade.fade-in {
  opacity: 1;
}

/* 모바일 디자인으로 */
@media (max-width: 746px) {
  .custom-container {
    margin: 0;
    padding: 15px;
    overflow-x: hidden;
    /* display: none; */
  }
}
</style>
