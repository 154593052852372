// stateManage.js
export default {
    state: {
      search: ''
    },
    mutations: {
      updateSearch (state, search) {
        state.search = search
      }
    }
  }
  