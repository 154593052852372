import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/components/HomeDesign.vue';
import CategoryDesignVue from './components/CategoryDesign.vue';
import EditPage from './components/EditPage.vue'
import ItemDetail from './components/ItemDetail.vue'
import NotFound from './components/NotFound.vue'
import SearchPage from './components/SearchPage.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main,
  },
  {
    path:'/edit',
    name:'EditPage',
    component : EditPage
  },
  {
    path:"/category/:categoryName/:id",
    name:"ItemDetail",
    component :ItemDetail,
    props:true
  },
  {
    path: '/category/:categoryName',
    name: 'Category',
    component: CategoryDesignVue,
    props: true,
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchPage,
    props: true,
  },
  {
    path: '/:Wrong(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});

export default router;
