import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, getFirestore, collection, query, where, getDocs, doc, updateDoc, increment } from 'firebase/firestore';

const firebaseConfig = {
  // Firebase configuration info
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

async function uploadImage(file, category) {
  const storageRef = ref(storage, `${category}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Handle the upload task progress
      }, 
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      }, 
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('File available at', downloadURL);
        resolve(downloadURL); // Resolve the promise with the download URL
      }
    );
  });
}


// async function fetchData(category) {
//   try {
//     const querySnapshot = await getDocs(collection(db, category));
//     const data = querySnapshot.docs.map((doc) => doc.data());
//     return data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     return [];
//   }
// }



const MAX_SIZE_MB = 4;
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
const CACHE_EXPIRATION_MINUTES = 3;

function getByteSize(str) {
  return new Blob([str]).size;
}

export async function fetchData(category) {
  const cacheKey = `data_${category}`;
  const cachedItem = JSON.parse(localStorage.getItem(cacheKey) || 'null');

  if (cachedItem) {
    const { data, timestamp } = cachedItem;
    const elapsedTimeMinutes = (Date.now() - timestamp) / (1000 * 60);

    // 유효 기간이 지나지 않았다면 캐시된 데이터 반환
    if (elapsedTimeMinutes < CACHE_EXPIRATION_MINUTES) {
      return data;
    }
  }

  // 새로운 데이터 불러오기
  try {
    const querySnapshot = await getDocs(collection(db, category));
    const data = querySnapshot.docs.map((doc) => doc.data());
    const serializedData = JSON.stringify({
      data,
      timestamp: Date.now(), // 현재 시간 추가
    });

    // 현재 저장된 데이터 크기 확인
    let currentSize = 0;
    const cacheKeys = [];
    for (let key in localStorage) {
      if (key.startsWith('data_')) {
        const size = getByteSize(localStorage[key] || '');
        currentSize += size;
        cacheKeys.push({ key, size }); // 크기 정보도 함께 저장
      }
    }

    // 오래된 데이터 삭제 로직
    cacheKeys.sort((a, b) => a.size - b.size); // 크기 순으로 정렬
    while (currentSize + getByteSize(serializedData) > MAX_SIZE_BYTES && cacheKeys.length > 0) {
      const oldestKey = cacheKeys.shift().key; // 가장 작은 크기의 데이터 키
      const oldestDataSize = getByteSize(localStorage[oldestKey] || '');
      localStorage.removeItem(oldestKey); // 삭제
      currentSize -= oldestDataSize;
    }

    // 데이터 저장
    localStorage.setItem(cacheKey, serializedData);

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
}

async function publishArticle(article, imageFile) {
  const { title, author, description, category, createdDate, comment } = article;

  try {
    const id = generateId(); // Generate a random ID

    // Upload the image and get the URL
    const imageURL = await uploadImage(imageFile, category);  // Pass the category to the uploadImage function

    const docRef = await addDoc(collection(db, category), {
      id,
      title,
      author,
      description, // This is the HTML markup
      imageURL, // Add the image URL to the Firestore document
      views: 0,
      comment,
      category,
      createdDate,
    });

    return docRef.id; // Return the document ID
  } catch (error) {
    console.error("Error adding document:", error);
    throw error;
  }
}


// Function to generate a random ID
function generateId() {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let id = "";
  for (let i = 0; i < 13; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    id += chars[randomIndex];
  }
  return id;
}

async function updateViews(category, id) {
  const q = query(collection(db, category), where("id", "==", id));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const docSnapshot = querySnapshot.docs[0]; // get the first document
    const docRef = doc(db, category, docSnapshot.id);

    try {
      await updateDoc(docRef, {
        views: increment(1)
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  } else {
    console.error("No document found with ID:", id);
  }
}

export { uploadImage, updateViews, db, publishArticle };
