<template>
  <div class="page text-center" style="width: 100%;">
    <h1>
      죄송합니다. 페이지를 찾을 수 없습니다.
    </h1>
    
  </div>
</template>

<script>
export default {

}
</script>

<style>
.page{
  height: 100vh;
}
</style>