<template>
  <div class="mainBanner" v-if="Object.entries(dbData).length != 0">
      <router-view
        :category="all_category"
        :dbData="dbData"
        :lastestnewsData="lastestnewsData"
        :topnewsData="topnewsData"
        :all_lastestnewsData="all_lastestnewsData"
        :all_topnewsData="all_topnewsData"
        :comment="comment"
        :ScrollValue="ScrollValue"
      ></router-view>
      <div class="icon-design">
        <i @click="moveTotop();" class="bi bi-arrow-up-circle-fill"></i>
      </div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <h5>About Us</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
              eget enim ut dolor fringilla commodo. Quisque nec eros ac lectus
              dignissim posuere. Nunc pulvinar tellus sed lacus facilisis, id
              feugiat mauris venenatis. Integer eget dignissim nulla, eu
              venenatis nulla.
            </p>
          </div>
          <div class="col-md-4">
            <h5>Contact Information</h5>
            <ul class="list-unstyled">
              <li>123 Street, City</li>
              <li>Phone: (123) 456-7890</li>
              <li>Email: info@example.com</li>
            </ul>
          </div>
          <div class="col-md-4">
            <h5>Follow Us</h5>
            <ul class="list-inline">
              <li class="list-inline-item">
                <a href="#"><i class="fab fa-facebook"></i></a>
              </li>
              <li class="list-inline-item">
                <a href="#"><i class="fab fa-twitter"></i></a>
              </li>
              <li class="list-inline-item">
                <a href="#"><i class="fab fa-instagram"></i></a>
              </li>
              <li class="list-inline-item">
                <a href="#"><i class="fab fa-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col text-center">
            <p>&copy; 2023 Your Website. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
  <div class="text-center" v-else style="width: 100%">
    <div>데이터 불러오는중</div>
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/fetchData";

export default {
  components: {},
  props: {
    ScrollValue: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dbData: {}, //전체 데이터가 들어 있는 객체
      topnewsData: {}, //인기 뉴스를 저장 (view가 많으면 인기뉴스) views를 기준으로 정렬 (많은 순)
      lastestnewsData: {}, //카테고리별로 최신 뉴스를 저장 (최신 뉴스) createdDate를 기준으로 정렬 (최신순)
      all_lastestnewsData: [], //모든 최신 뉴스를 저장하는 데이터
      all_topnewsData: [], //모든 인기 뉴스를 저장하는 데이터
      all_category: [
        "economy",
        "hotissue",
        "international",
        "northkorea",
        "politics",
        "science",
        "society",
      ],
      comment: [],
    };
  },
  async created() {
    this.fetchData();
  },
  methods: {
    moveTotop(){
      document.body.scrollTop = 0; // Safari 용
            document.documentElement.scrollTop = 0; 
    },
    //전체 데이터를 불러오는 함수
    async fetchData() {
      try {
        const categories = [
          "economy",
          "hotissue",
          "international",
          "northkorea",
          "politics",
          "science",
          "society",
        ];

        const dbData = {};

        for (const category of categories) {
          // fetchData 함수를 사용하여 Firestore 쿼리를 수행합니다.
          const items = await fetchData(category);
          dbData[category] = items;
        }

        this.dbData = dbData;
        this.loadLastestNews();
        this.loadTopNews();
        this.allloadLastestNews();
        this.allloadTopNews();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    //최신 뉴스를 카테고리 별로 정렬 및 저장 하는 함수
    async loadLastestNews() {
      //dbData의 카테고리 별로 정렬 dbData[category][index]로 접근 해야함
      for (const category of this.all_category) {
        for (let i = 0; i < 5; i++) {
          this.lastestnewsData[category] = this.dbData[category].sort(function (
            a,
            b
          ) {
            return b.createdDate - a.createdDate;
          });
        }
      }
    },
    //인기 뉴스를 카테고리 별로 정렬 및 저장 하는 함수
    async loadTopNews() {
      //dbData의 카테고리 별로 정렬 dbData[category][index]로 접근 해야함
      for (const category of this.all_category) {
        for (let i = 0; i < 5; i++) {
          this.topnewsData[category] = this.dbData[category].sort(function (
            a,
            b
          ) {
            return b.views - a.views;
          });
        }
      }
    },
    // 최신 뉴스를 카테고리 별로 정렬 및 저장하는 함수
    async allloadLastestNews() {
      let allNews = [];

      for (const category of this.all_category) {
        let sortedNews = this.dbData[category].sort(
          (a, b) => b.createdDate - a.createdDate
        );
        this.lastestnewsData[category] = sortedNews.slice(0, 5);

        // 모든 뉴스를 하나의 배열로 합칩니다.
        allNews = allNews.concat(sortedNews);
      }

      // 모든 뉴스를 최신 순으로 정렬합니다.
      this.all_lastestnewsData = allNews.sort(
        (a, b) => b.createdDate - a.createdDate
      );
    },
    // 인기 뉴스를 카테고리 별로 정렬 및 저장하는 함수
    async allloadTopNews() {
      let allNews = [];

      for (const category of this.all_category) {
        let sortedNews = this.dbData[category].sort(
          (a, b) => b.views - a.views
        );
        this.topnewsData[category] = sortedNews.slice(0, 5);

        // 모든 뉴스를 하나의 배열로 합칩니다.
        allNews = allNews.concat(sortedNews);
      }

      // 모든 뉴스를 최신 순으로 정렬합니다.
      this.all_topnewsData = allNews.sort((a, b) => b.views - a.views);
    },
    computed: {
      changecomment() {
        this.comment = this.all_lastestnewsData["description"].slice(0, 100);
      },
    },
    
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&family=Roboto:wght@100;400&display=swap");
.mainBanner {
  font-family: "Oswald", sans-serif;
  font-family: "Roboto", sans-serif;
}
.icon-design{
  font-size: 50px;
  position: absolute;
  left: 90%;
}
@media screen and (max-width: 745px) {
  .icon-design{
  display: none;
}
}

</style>
