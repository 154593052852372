<template>
  <div class="container-fluid px-0 ">
    <div class="row" style="padding: auto 0 ">
      <!-- Main Content -->
      <div class="col pe-7 ps-7 mb-5 area">
        <div class="main-news">
          <!-- Main Banner -->
          <div class="mb-4" v-if="newsItem">
            <div class="mt-2 text-center">
              <h2>{{ newsItem.title }}</h2>
              <p>
                <small>Author: {{ newsItem.author }}</small
                ><br />
              </p>
              <p>
                <small>Created Date: {{ newsItem.createdDate }}</small>
              </p>
            </div>
            <div
              class="text-start description"
              v-html="newsItem.description"
            ></div>
          </div>
        </div>
      </div>
      <div class="col-md-3 pl-md-4 moblie">
        <div class="sidebar-box mb-3 border border-black margin-design">
          <h5 class="sidebar-title">여기에 광고 넣어야함</h5>
          <p class="sidebar-content">광고</p>
        </div>
        <div class="d-flex flex-column">
          <div class="sidebar-box mb-3 margin-design" style="width: auto">
            <div
              v-for="(news, index) in all_lastestnewsData.slice(6, 14)"
              :key="index"
              class="border-bottom border-black mouseon mb-2"
            >
              <p
                @click="navigateToDetail(news.id, news.category)"
                class="p fw-bold"
              >
                {{ news.title }}
              </p>
              <p
                @click="navigateToDetail(news.id, news.category)"
                class="p fw-lighter"
              >
              {{ news.comment.slice(0, 200) }}...

              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Side Banner -->

      <div class="text-black h-100">
        <h5>other News</h5>
        <div class="sidebar-divider"></div>
        <div
          class="pt-3 mouseon"
          v-for="(news, index) in otherNews.slice(0, 5)"
          :key="index"
        >
          <h6 @click="navigateToDetail(news.id, categoryName)">
            {{ news.title }}
          </h6>
          <div class="sidebar-divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    dbData: {
      type: Object,
      required: true,
    },
    all_lastestnewsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newsItem: null,
      otherNews: [],
    };
  },
  created() {
    this.newsItem = this.dbData[this.categoryName].find(
      (item) => item.id === this.id
    );
    this.otherNews = this.dbData[this.categoryName].filter(
      (item) => item.id !== this.id
    );
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return ""; // Handle case when date is undefined or null
      }

      const parsedDate = new Date(date);
      if (isNaN(parsedDate)) {
        return ""; // Handle case when date cannot be parsed
      }

      const options = { year: "numeric", month: "numeric", day: "numeric" };
      const formattedDate = parsedDate.toLocaleDateString(undefined, options);
      return formattedDate.replace(/\./g, ". ");
    },
    //클릭시 페이지 이동 하는 함수
    navigateToDetail(itemId, categoryName) {
      const routeData = {
        name: "ItemDetail",
        params: { categoryName: categoryName, id: itemId },
      };

      // Get the current base URL
      const baseUrl = window.location.origin;

      // Create the URL for the route
      const url = this.$router.resolve(routeData).href;

      // Open the URL in a new tab
      window.open(baseUrl + url, "_blank");
    },
  },

  watch: {
    dbData: {
      immediate: true,
      handler(newValue) {
        this.newsItem = newValue[this.categoryName].find(
          (item) => item.id === this.id
        );
        this.otherNews = newValue[this.categoryName].filter(
          (item) => item.id !== this.id
        );
      },
    },
  },
};
</script>
<style scoped>
.mouseon h6:hover {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
/* .area {
  max-height: calc(100vh - 140px); 
  overflow-y: auto; 
  scrollbar-width: none; 
} */

h5 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  margin-left: 0;
  margin-right: 0;
}

h6 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  margin-left: 0;
  margin-right: 0;
}

.img-size p img {
  width: 300px; /* Set the width of the image */
  height: auto; /* Maintain aspect ratio */
}

p {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.sidebar-divider {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
}
.description::v-deep img {
  width: 100%;
  height: 540px;
}
.description::v-deep {
    padding: 0 15px;
    font-weight: 400;
  }
.container {
  margin: 0 15px;
}

.pe-7 {
  padding-right: 7rem;
}
.ps-7 {
  padding-left: 7rem;
}
@media screen and (max-width: 1300px) {
  .moblie{
    display: none;
  }
  .pe-7 {
    padding-right: 0;
  }
  .ps-7 {
    padding-left: 0;
  }
  .description::v-deep {
    padding: 0 15px;
    font-size: 14px;
  }
  .description::v-deep img {
    height: 250px;
  }
  .area {
    max-height: none; /* max-height 속성 초기화 */
    overflow-y: visible; /* overflow-y 속성 초기화 */
    scrollbar-width: auto; /* scrollbar-width 속성 초기화 */
  }
  .mb-5{
    margin-bottom: 0px;
  }
}
</style>
